import { Typography, type SxProps } from '@mui/material'
import { Icon, borderRadius, colors, spacing, type ColorScale, type CompoundIconName } from '@orus.eu/pharaoh'

type TagProps =
  | {
      variant: 'colorScale'
      sx?: SxProps
      text: string
      icon?: CompoundIconName
      colorScale?: ColorScale
    }
  | {
      variant: 'colors'
      sx?: SxProps
      text: string
      icon?: CompoundIconName
      color: string
      backgroundColor: string
    }

const defaultColorScale = colors.blue

export function Tag(props: TagProps): JSX.Element {
  const color = props.variant === 'colorScale' ? (props.colorScale?.[900] ?? defaultColorScale[900]) : props.color

  const backgroundColor =
    props.variant === 'colorScale' ? (props.colorScale?.[200] ?? defaultColorScale[200]) : props.backgroundColor

  return (
    <Typography
      variant="body2Medium"
      sx={{
        color,
        backgroundColor,
        whiteSpace: 'nowrap',
        display: 'inline-flex',
        alignItems: 'center',
        paddingTop: spacing[20],
        paddingBottom: spacing[20],
        paddingLeft: spacing[40],
        paddingRight: spacing[40],
        borderRadius: borderRadius[40],
        ...props.sx,
      }}
    >
      {typeof props.icon !== 'undefined' && (
        <>
          <Icon icon={props.icon} />
          &nbsp;&nbsp;
        </>
      )}
      {props.text}
    </Typography>
  )
}
